import React, { Component } from 'react';

export class Loading extends Component {
    static displayName = Loading.name;

    render() {

        return (
            <div style={{ textAlign: "center" }}>
                <div className="spinner-border" role="status" style={{ color: "#00235d" }}>
                    <span className="sr-only">Loading...</span>
                </div>
                <div>Loading...</div>
            </div>

        );
    }
}
