import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export class EventResultsTable extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
            Loading: true,
            SelectedSeasonID: props.SeasonID,
            SelectedRaceNumber: props.RaceNumber,
            SelectedRaceTypeID: props.TypeID,
            SelectedGender: props.Gender,
            IsDuathlon: null,
            IsHandicap: null,

            Results: null,

            SortedField: "position",
            sortPosition: "asc",
            sortFirstName: "desc",
            sortLastName: "desc",
            sortHandicapTimeString: "desc",
            sortHandicapString: "desc",
            sortRawTimeString: "desc",
            sortLegOneTimeString: "desc",
            sortTransitionOneTimeString: "desc",
            sortLegTwoTimeString: "desc",
            sortTransitionTwoTimeString: "desc",
            sortLegThreeTimeString: "desc",
        }
    }

    // Called once on initial load
    componentDidMount() {
        this.getEventDetails();
    } 

    // Update when the the props passed to the component are changed
    componentDidUpdate(prevProps) {
        if (prevProps.SeasonID !== this.props.SeasonID || prevProps.RaceNumber !== this.props.RaceNumber || prevProps.TypeID !== this.props.TypeID || prevProps.Gender !== this.props.Gender) {
            this.setState({
                SelectedSeasonID: this.props.SeasonID,
                SelectedRaceNumber: this.props.RaceNumber,
                SelectedRaceTypeID: this.props.TypeID,
                SelectedGender: this.props.Gender,
            }, this.getEventDetails);
        }
    }

    render() {
        let lResults = null;

        if (this.state.Loading === false && this.state.Results !== null) {
            
            let sortedResults = this.getFilteredResults();
           
            lResults = sortedResults.map(result => {
                return (
                    <tr key={result.athleteID}>
                        <td>{result.position}</td>
                        <td><Link className="grayLink" to={{ pathname: '/athlete-results', state: { AthleteID: result.athleteID, FirstName: result.firstName, LastName: result.lastName} }}>{result.firstName}</Link></td>
                        <td><Link className="grayLink" to={{ pathname: '/athlete-results', state: { AthleteID: result.athleteID, FirstName: result.firstName, LastName: result.lastName } }}>{result.lastName}</Link></td>
                        {
                           this.state.IsHandicap === true ?
                                <td>{result.handicapTimeString === "" ? "DNF" : result.handicapTimeString}</td>
                                : null
                        }
                        {
                            this.state.IsHandicap === true ?
                                <td>-{result.handicapString}</td>
                                : null
                        }
                        <td>{result.rawTimeString === "" ? "DNF" : result.rawTimeString}</td>
                        <td>{result.legOneTimeString === "" ? "-" : result.legOneTimeString}</td>
                        <td>{result.transitionOneTimeString === "" ? "-" : result.transitionOneTimeString}</td>
                        <td>{result.legTwoTimeString === "" ? "-" : result.legTwoTimeString}</td>
                        <td>{result.transitionTwoTimeString === "" ? "-" : result.transitionTwoTimeString}</td>
                        <td>{result.legThreeTimeString === "" ? "-" : result.legThreeTimeString}</td>
                    </tr>
                );
            });
        }

        return (
            <div className="row">
                <div className="col-12">
                    <table className="resultsTable">
                        <thead>
                            <tr>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("position")}>
                                        Position <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("firstName")}>
                                        First Name <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("lastName")}>
                                        Last Name <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                {
                                    this.state.IsHandicap === true ? 
                                        <th>
                                            <button className="btn btnSort" onClick={() => this.sortResults("handicapTimeString")}>
                                                H'Cap Time <FontAwesomeIcon icon="sort" color="white" />
                                            </button>
                                        </th>
                                        : null
                                }
                                {
                                    this.state.IsHandicap === true ? 
                                        <th>
                                            <button className="btn btnSort" onClick={() => this.sortResults("handicapString")}>
                                                H'Cap <FontAwesomeIcon icon="sort" color="white" />
                                            </button>
                                        </th>
                                        : null
                                }
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("rawTimeString")}>
                                        Raw Time <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("legOneTimeString")}>
                                        { this.state.IsDuathlon === true ? "Run" : "Swim" } <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("transitionOneTimeString")}>
                                        T1 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("legTwoTimeString")}>
                                        Bike <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("transitionTwoTimeString")}>
                                        T2 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort" onClick={() => this.sortResults("legThreeTimeString")}>
                                        Run <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lResults}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    async getAllResultsForRace() {

        const resultsResponse = await fetch(`/Stats/getAllResultsForRace?aSeasonID=${this.state.SelectedSeasonID}&aRaceNumber=${this.state.SelectedRaceNumber}&aTypeID=${this.state.SelectedRaceTypeID}&aIsHandicap=${this.state.IsHandicap}`);
        const results = await resultsResponse.json();

        this.setState({
            Loading: false,
            Results: results,
        });
    }

    async getEventDetails() {
        const eventResponse = await fetch(`/Stats/GetRaceDetails?aSeasonID=${this.state.SelectedSeasonID}&aRaceNumber=${this.state.SelectedRaceNumber}&aTypeID=${this.state.SelectedRaceTypeID}`);
        const event = await eventResponse.json();
        this.setState({
            IsDuathlon: event.isDuathlon,
            IsHandicap: event.isHandicap,
        }, this.getAllResultsForRace);
    }

    // Filter results based on selected gender and sorting preferences
    // NOTE: this function is not asynchronous becuase the result is needed before the next step can take place (will break if asyncronous)
     getFilteredResults() {
        let lFilteredResults = this.state.Results;

        if (this.state.SelectedGender !== "all") {
            lFilteredResults = lFilteredResults.filter(result => result.gender.trim().toLowerCase() === this.state.SelectedGender);
        }

         if (this.state.IsHandicap === true) {
             // sort into ascending handicap time so that position based on handicap time can be displayed
             lFilteredResults = lFilteredResults.sort(function (a, b) {
                 // equal items sort equally
                 if (a.handicapTimeString === b.handicapTimeString) {
                     return 0;
                 }
                 // nulls sort after anything else
                 else if (a.handicapTimeString === null || a.handicapTimeString === "") {
                     return 1;
                 }
                 else if (b.handicapTimeString === null || b.handicapTimeString === "") {
                     return -1;
                 }
                 // otherwise, if we're ascending, lowest sorts first
                 else {
                     return a.handicapTimeString < b.handicapTimeString ? -1 : 1;
                 }
             });

             // Assign positions to results based off handicap time
             let position = 1;

             for (let i = 0; i < lFilteredResults.length; i++) {
                 if (lFilteredResults[i].handicapTimeString !== "") {
                     lFilteredResults[i].position = position;
                     position++;
                 }
             }
         }
         else {
             // sort into ascending raw time so that position based on raw time can be displayed
             lFilteredResults = lFilteredResults.sort(function (a, b) {
                 // equal items sort equally
                 if (a.rawTimeString === b.rawTimeString) {
                     return 0;
                 }
                 // nulls sort after anything else
                 else if (a.rawTimeString === null || a.rawTimeString === "") {
                     return 1;
                 }
                 else if (b.rawTimeString === null || b.rawTimeString === "") {
                     return -1;
                 }
                 // otherwise, if we're ascending, lowest sorts first
                 else {
                     return a.rawTimeString < b.rawTimeString ? -1 : 1;
                 }
             });

             // Assign positions to results based off handicap time
             let position = 1;

             for (let i = 0; i < lFilteredResults.length; i++) {
                 if (lFilteredResults[i].rawTimeString !== "") {
                     lFilteredResults[i].position = position;
                     position++;
                 }
             }
         }

        // Sort based off users preference
        let sortBy = this.state.SortedField;
        let sortOrder = this.state["sort" + sortBy.charAt(0).toUpperCase() + sortBy.slice(1)]; // Capitalise first letter to match the existing state name

        let sortedResults = lFilteredResults.sort(function (a, b) {
            // equal items sort equally
            if (a[sortBy] === b[sortBy]) {
                return 0;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (sortOrder === "asc") {
                return a[sortBy] < b[sortBy] ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return sortedResults;
    }

    // Update users sorting preferences
    async sortResults(column) {
        let stateName = "sort" + column.charAt(0).toUpperCase() + column.slice(1); // Capitalise first letter to match the existing state name
        let sortOrder = this.state[stateName];
        let direction;

        if (sortOrder === "asc") {
            direction = "desc"
        }
        else {
            direction = "asc"
        }

        this.setState({
            SortedField: column,
            [stateName]: direction
        });
    }
}