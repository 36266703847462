import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import { EventResults } from './EventResults';
import './index.css';

// Create a library of font awesome icons that can be used globally
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSort, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { AthleteResults } from './AthleteResults';

library.add(faSort, faArrowLeft)

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Layout>
            <Switch>
                <Route exact path='/' component={EventResults} />
                <Route exact path='/athlete-results' component={AthleteResults} />
            </Switch>
        </Layout>
    </BrowserRouter>,
    rootElement);

