import React, { Component } from 'react';
import { AthleteResultsTable } from './components/AthleteResultsTable';
import { AthleteLineGraph } from './components/AthleteLineGraph';
import { AthleteResultsSummaryTable } from './components/AthleteResultsSummaryTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class AthleteResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Loading: true,
            SelectedAthleteID: props.location.state.AthleteID,
            FirstName: props.location.state.FirstName,
            LastName: props.location.state.LastName,
            Results: null
        }

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.getAllResultsForAthlete();
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row" style={{ marginBottom: "40px", textAlign: "center" }}>
                        <div className="col-12" style={{ textAlign: "left" }}>
                            <button className="btn btnBack" onClick={this.goBack}><FontAwesomeIcon icon="arrow-left" /> BACK</button> 
                        </div>
                        <div className="col-12">
                            <h1>{this.state.FirstName + " " + this.state.LastName}</h1>
                        </div>
                        <div className="col-12">
                            <h3>{this.state.Results ? this.state.Results.length + (this.state.Results.length === 1 ? " Event" : " Events") : ""}</h3>
                        </div>
                    </div>
                </div>
                <div className="container" style={{marginBottom:"60px"}}>
                    <AthleteResultsSummaryTable Results={this.state.Results} />
                    <AthleteLineGraph Results={this.state.Results}/>
                    <AthleteResultsTable Results={this.state.Results} />
                </div>
            </div>
        );
    }

    goBack() {
        this.props.history.goBack();
    }

    async getAllResultsForAthlete() {

        const resultsResponse = await fetch(`/Stats/getAllResultsForAthlete?aAthleteID=${this.state.SelectedAthleteID}`);
        const results = await resultsResponse.json();

        this.setState({
            Loading: false,
            Results: results,
        });
    }
}