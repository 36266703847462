import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";


export class AthleteLineGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Results: props.Results,
            GraphData: [],
            SelectedActivity: "overall",

            overallYMin: "auto",
            overallYMax: "auto",
            swimYMin: "auto",
            swimYMax: "auto",
            bikeYMin: "auto",
            bikeYMax: "auto",
            runYMin: "auto",
            runYMax: "auto",
        }

        this.ActivityChanged = this.ActivityChanged.bind(this);
    }

    ActivityChanged(event) {
        this.setState({
            SelectedActivity: event.target.value
        });
    }

    // Update when the the props passed to the component are changed
    componentDidUpdate(prevProps) {
        if (prevProps.Results !== this.props.Results) {
            this.setState({
                Results: this.props.Results,
            }, this.getGraphData);
        }
    }

    render() {
        return (
            <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-12">
                    <div id="dvAthleteGraphDropDown" className="customSelect" style={{display: "inline-block", width:"300px", marginLeft:"75px", marginBottom:"10px"}}>
                        <select className="form-control" onChange={this.ActivityChanged.bind(this)} value={this.state.SelectedActivity}>
                            <option key="overall" value="overall">Overall</option>
                            <option key="swim" value="swim">Swim</option>
                            <option key="bike" value="bike">Bike</option>
                            <option key="run" value="run">Run</option>
                        </select>
                    </div>
                </div>
                <div className="col-12">
                    <ResponsiveContainer height={400} width="100%">
                        <LineChart data={this.state.GraphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" stroke="#00235d" strokeWidth={2} dy={5} />
                            <YAxis type="number" scale={this.state.SelectedActivity === "swim" || this.state.SelectedActivity === "run" ? "time" : "auto"}
                                tickFormatter={this.formatYAxis}
                                domain={
                                    this.state.SelectedActivity === "swim" ? [this.state.swimYMin, this.state.swimYMax] :
                                        this.state.SelectedActivity === "bike" ? [this.state.bikeYMin, this.state.bikeYMax] :
                                            this.state.SelectedActivity === "run" ? [this.state.runYMin, this.state.runYMax] :
                                                [this.state.overallYMin, this.state.overallYMax]
                                }
                                stroke="#00235d"
                                strokeWidth={2}
                                label={
                                    <text x="-180" y="20" textAnchor="middle" fill="#666666" transform="rotate(270, 5, 7)" style={{ fontSize: "20px" }}>
                                        {
                                            this.state.SelectedActivity === "swim" ? "Pace/100m" :
                                                this.state.SelectedActivity === "bike" ? "kph" :
                                                    this.state.SelectedActivity === "run" ? "Pace/km" :
                                                        "kph"
                                        }
                                    </text>
                            } />
                            <Tooltip content={<CustomizedTooltip />}/>
                            <Line type="monotone" dataKey={this.state.SelectedActivity} stroke="#00235d" strokeWidth={4} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
    async getGraphData() {
        let data = [];
        let OverallYMin = "auto";
        let OverallYMax = "auto";
        let SwimYMin = "auto";
        let SwimYMax = "auto";
        let BikeYMin = "auto";
        let BikeYMax = "auto";
        let RunYMin = "auto";
        let RunYMax = "auto";


        if (this.state.Results) {
            // order results in ascending date order
            let orderedResults = this.state.Results.sort(function (a, b) {
                // equal items sort equally
                if (new Date(a.raceDate) === new Date(b.raceDate)) {
                    return 0;
                }
                // otherwise, if we're ascending, lowest sorts first
                else {
                    return new Date(a.raceDate) < new Date(b.raceDate) ? -1 : 1;
                }
            });

            orderedResults.forEach(function (item, index) {

                let aDate = new Date(item.raceDate);
                let day = aDate.getDate();
                let month = aDate.getMonth() + 1; // + 1 becuase js months start at 0
                let year = aDate.getFullYear();

                let formattedDate = (day < 10 ? "0" + day : day) + "/" + (month < 10 ? "0" + month : month) + "/" + year;


                // if the race is a duathlon the leg 1 and leg 3 are both runs so we average the result and dont have a swim
                if (item.isDuathlon === true) {

                    let runPace = null;

                    let runTime = null;
                    let runDistance = 0;

                    if (item.legOneTime !== null || item.legThreeTime !== null) {
                        runTime = 0;
                        if (item.legOneTime !== null) {
                            runTime += item.legOneTime.value.ticks / 10000; // Solution 2 https://www.codeproject.com/Questions/859061/How-do-I-convert-Csharp-DateTime-Ticks-to-javascri 
                            runDistance += item.legOneDistance;
                        }
                        if ( item.legThreeTime !== null) {
                            runTime += item.legThreeTime.value.ticks / 10000;
                            runDistance += item.legThreeDistance;
                        }

                        runPace = runTime / runDistance;
                    }

                    data.push({
                        date: formattedDate,
                        swim: null,
                        bike: item.legTwoTime !== null ? Math.round(item.legTwoDistance / item.legTwoTime.value.totalHours, 1) : null,
                        run: Math.round(runPace, 2),
                        overall: item.legOneTime !== null && item.legTwoTime !== null && item.legThreeTime !== null ? Math.round((item.legOneDistance + item.legTwoDistance + item.legThreeDistance) / (item.legOneTime.value.totalHours + item.legTwoTime.value.totalHours + item.legThreeTime.value.totalHours), 1) : null
                    });

                }
                else {
                    // divide leg1 distance by 1000 to convert from m to km
                    data.push({
                        date: formattedDate,
                        swim: item.legOneTime !== null ? (item.legOneTime.value.ticks / 10000) / (item.legOneDistance / 100) : null,
                        bike: item.legTwoTime !== null ? Math.round(item.legTwoDistance / item.legTwoTime.value.totalHours, 1) : null,
                        run: item.legThreeTime !== null ? (item.legThreeTime.value.ticks / 10000) / item.legThreeDistance : null,
                        overall: item.legOneTime !== null && item.legTwoTime !== null && item.legThreeTime !== null ? Math.round(((item.legOneDistance / 1000) + item.legTwoDistance + item.legThreeDistance) / (item.legOneTime.value.totalHours + item.legTwoTime.value.totalHours + item.legThreeTime.value.totalHours), 1) : null
                    });
                }
            });

            let swimResults = data.filter(obj => obj.swim);
            if (swimResults.length > 0) {
                SwimYMin = Math.min.apply(Math, swimResults.map(function (obj) { return obj.swim; }))
                SwimYMax = Math.max.apply(Math, swimResults.map(function (obj) { return obj.swim; }))

                SwimYMin = new Date(SwimYMin).setSeconds(new Date(SwimYMin).getSeconds() -10)
                SwimYMax = new Date(SwimYMax).setSeconds(new Date(SwimYMax).getSeconds() + 10)
            }

            let bikeResults = data.filter(obj => obj.bike);
            if (bikeResults.length > 0) {
                BikeYMin = Math.min.apply(Math, bikeResults.map(function (obj) { return obj.bike; }))
                BikeYMax = Math.max.apply(Math, bikeResults.map(function (obj) { return obj.bike; }))
               
                BikeYMin = BikeYMin - 2;
                BikeYMax = BikeYMax + 2;
            }

            let runResults = data.filter(obj => obj.run);
            if (runResults.length > 0) {
                RunYMin = Math.min.apply(Math, runResults.map(function (obj) { return obj.run; }))
                RunYMax = Math.max.apply(Math, runResults.map(function (obj) { return obj.run; }))

                RunYMin = new Date(RunYMin).setSeconds(new Date(RunYMin).getSeconds() - 10)
                RunYMax = new Date(RunYMax).setSeconds(new Date(RunYMax).getSeconds() + 10)
            }

            let overallResults = data.filter(obj => obj.overall);
            if (overallResults.length > 0) {
                OverallYMin = Math.min.apply(Math, overallResults.map(function (obj) { return obj.overall; }))
                OverallYMax = Math.max.apply(Math, overallResults.map(function (obj) { return obj.overall; }))

                OverallYMin = OverallYMin - 2;
                OverallYMax = OverallYMax + 2;
            }
        }
        this.setState({
            GraphData: data,
            overallYMin: OverallYMin,
            overallYMax: OverallYMax,
            swimYMin: SwimYMin,
            swimYMax: SwimYMax,
            bikeYMin: BikeYMin,
            bikeYMax: BikeYMax,
            runYMin: RunYMin,
            runYMax: RunYMax
        });
    }

    formatYAxis = (tickItem) => {
        if (this.state.SelectedActivity === "swim" || this.state.SelectedActivity === "run") {
            return tickItem.getMinutes() + ":" + (tickItem.getSeconds() < 10 ? "0" + tickItem.getSeconds() : tickItem.getSeconds());
        }
        else {
            return tickItem;
        }
    }
}

class CustomizedTooltip extends React.PureComponent {
    render() {
        const { label, payload } = this.props;

        if (!label || !payload || payload.length === 0) return null;

        let units;
        if (payload[0].name === "swim") {
            units = "/100m";
        }
        else if (payload[0].name === "run") {
            units = "/km";
        }
        else {
            units = "kph";
        }

        let labelValue;

        if (payload[0].name === "swim" || payload[0].name === "run") {

            let time = new Date(payload[0].value);

            labelValue = time.getMinutes() + ":" + (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds()) + units;
        }
        else {
            labelValue = payload[0].value.toFixed(1) + units;
        }

        return (
            <div style={{ backgroundColor: "white", color: "666666", textAlign: "center", border:"1px solid #666666", padding:"5px"  }}>
                <div>{`${label}`}</div>
                <div>
                    {
                        labelValue
                    }
                </div>
            </div>
        );
    }
}