import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class AthleteResultsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Results: props.Results,

            SortedField: "seasonEnd",
            sortPosition: "asc",
            sortSeasonEnd: "desc",
            sortRaceNumber: "desc",
            sortRaceType: "desc",
            sortIsDuathlon: "desc",
            sortHandicapTimeString: "desc",
            sortHandicapString: "desc",
            sortRawTimeString: "desc",
            sortLegOneTimeString: "desc",
            sortTransitionOneTimeString: "desc",
            sortLegTwoTimeString: "desc",
            sortTransitionTwoTimeString: "desc",
            sortLegThreeTimeString: "desc",
        }
    }

    // Update when the the props passed to the component are changed
    componentDidUpdate(prevProps) {
        if (prevProps.Results !== this.props.Results) {
            this.setState({
                Results: this.props.Results,
            });
        }
    }

    render() {
        let lResults = null;

        if (this.state.Results !== null) {

            let sortedResults = this.getFilteredResults();

            lResults = sortedResults.map(result => {
                return (
                    <tr key={result.raceID}>
                        <td>{result.seasonStart + "-" + result.seasonEnd}</td>
                        <td>{result.raceNumber}</td>
                        <td>{result.raceType}</td>
                        <td>{result.isDuathlon === true ? "Yes" : "No"}</td>
                        <td>{result.isHandicap === true ? result.handicapTimeString : "N/A"}</td>
                        <td>{result.isHandicap === true ? result.handicapString : "N/A"}</td>
                        <td>{result.rawTimeString === "" ? "DNF" : result.rawTimeString}</td>
                        <td>{result.legOneTimeString === "" ? "-" : result.legOneTimeString}</td>
                        <td>{result.transitionOneTimeString === "" ? "-" : result.transitionOneTimeString}</td>
                        <td>{result.legTwoTimeString === "" ? "-" : result.legTwoTimeString}</td>
                        <td>{result.transitionTwoTimeString === "" ? "-" : result.transitionTwoTimeString}</td>
                        <td>{result.legThreeTimeString === "" ? "-" : result.legThreeTimeString}</td>
                    </tr>
                );
            });
        }

        return (
            <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-12">
                    <h2 style={{marginBottom:"15px"}}>Previous Results</h2>
                    <table className="resultsTable" style={{textAlign:"center"}}>
                        <thead>
                            <tr style={{color:"white"}}>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("seasonEnd")}>
                                    Season <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("raceNumber")}>
                                        Race # <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("raceType")}>
                                        Type <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("isDuathlon")}>
                                        Duathlon <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("handicapTimeString")}>
                                        H'Cap Time <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("handicapString")}>
                                        H'Cap <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("rawTimeString")}>
                                        Raw Time <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("legOneTimeString")}>
                                        Leg 1 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("transitionOneTimeString")}>
                                        T1 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("legTwoTimeString")}>
                                        Leg 2 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("transitionTwoTimeString")}>
                                        T2 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                                <th>
                                    <button className="btn btnSort center" onClick={() => this.sortResults("legThreeTimeString")}>
                                        Leg 3 <FontAwesomeIcon icon="sort" color="white" />
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lResults}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getFilteredResults() {
        let lFilteredResults = this.state.Results;

        // Sort based off users preference
        let sortBy = this.state.SortedField;
        let sortOrder = this.state["sort" + sortBy.charAt(0).toUpperCase() + sortBy.slice(1)]; // Capitalise first letter to match the existing state name

        let sortedResults = lFilteredResults.sort(function (a, b) {
            // equal items sort equally
            if (a[sortBy] === b[sortBy]) {
                return 0;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (sortOrder === "asc") {
                return a[sortBy] < b[sortBy] ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return sortedResults;
    }

    // Update users sorting preferences
    async sortResults(column) {
        let stateName = "sort" + column.charAt(0).toUpperCase() + column.slice(1); // Capitalise first letter to match the existing state name
        let sortOrder = this.state[stateName];
        let direction;

        if (sortOrder === "asc") {
            direction = "desc"
        }
        else {
            direction = "asc"
        }

        this.setState({
            SortedField: column,
            [stateName]: direction
        });
    }
}