import React, { Component } from 'react';
import { EventDetails } from './components/EventDetails';
import { EventResultsTable } from './components/EventResultsTable';
import { Loading } from './components/Loading';

export class EventResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,

            SelectedSeasonID: null,
            SelectedRaceNumber: null,
            SelectedRaceTypeID: null,
            SelectedGender: "all",

            Seasons: null,
            RaceNumbers: null,
            RaceTypes: null,
        }

        this.SeasonChanged = this.SeasonChanged.bind(this);
        this.RaceChanged = this.RaceChanged.bind(this);
        this.TypeChanged = this.TypeChanged.bind(this);
        this.GenderChanged = this.GenderChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.history.location.state) {
            this.setState({
                SelectedSeasonID: this.props.history.location.state.SelectedSeasonID,
                SelectedRaceNumber: this.props.history.location.state.SelectedRaceNumber,
                SelectedRaceTypeID: this.props.history.location.state.SelectedRaceTypeID,
                SelectedGender: this.props.history.location.state.SelectedGender
            }, this.getAllSeasons);
        }
        else {
            this.getAllSeasons();
        }
    }

    SeasonChanged(event) {
        this.getAllRacesForSeason(event.target.value);
    }
    RaceChanged(event) {
        this.getAllTypesForRace(this.state.SelectedSeasonID, event.target.value);
    }
    TypeChanged(event) {
        this.setState({ SelectedRaceTypeID: event.target.value }, this.updateHistory);
    }
    GenderChanged(event) {
        this.setState({ SelectedGender: event.target.value }, this.updateHistory);
    }

    /* UPDATE THE HISTORY SO THAT WHEN THIS.PROPS.HISTORY .goBack is called the current state of the page is maintained */
    updateHistory = () => {
        this.props.history.push({
            pathname: "/",
            state: {
                "SelectedSeasonID": this.state.SelectedSeasonID,
                "SelectedRaceNumber": this.state.SelectedRaceNumber,
                "SelectedRaceTypeID": this.state.SelectedRaceTypeID,
                "SelectedGender": this.state.SelectedGender
            }
        })
    };

    render() {
        let seasonOptions = null;
        if (this.state.Seasons) {
            seasonOptions = this.state.Seasons.map((el) => <option key={el.id} value={el.id}>{el.startYear + "-" + el.endYear}</option>);
        }

        let raceOptions = null;
        if (this.state.RaceNumbers) {
            raceOptions = this.state.RaceNumbers.map((el) => <option key={el} value={el}>{el}</option>);
        }

        let typeOptions = null;
        if (this.state.RaceTypes) {
            typeOptions = this.state.RaceTypes.map((el) => <option key={el.id} value={el.id}>{el.type}</option>);
        }

        let content =
            <div>
                <div style={{ height: "100px", alignItems: "center", justifyContent: "center", display: "flex" }}><Loading /></div>
            </div>;

        if (this.state.Loading === false) {
            content = <div className="container">
                <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="col-12 col-md-3">
                        <div className="selectLabel">Season</div>
                        <div className="customSelect">
                            <select className="form-control" onChange={this.SeasonChanged.bind(this)} value={this.state.SelectedSeasonID}>
                                {seasonOptions}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="selectLabel">Race Number</div>
                        <div className="customSelect">
                            <select className="form-control" onChange={this.RaceChanged.bind(this)} value={this.state.SelectedRaceNumber}>
                                {raceOptions}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="selectLabel">Race Type</div>
                        <div className="customSelect">
                            <select className="form-control" onChange={this.TypeChanged.bind(this)} value={this.state.SelectedRaceTypeID}>
                                {typeOptions}
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="selectLabel">Gender</div>
                        <div className="customSelect">
                            <select className="form-control" onChange={this.GenderChanged.bind(this)} value={this.state.SelectedGender}>
                                <option key="all" value="all">All</option>
                                <option key="female" value="female">Female</option>
                                <option key="male" value="male">Male</option>
                            </select>
                        </div>
                    </div>
                </div>

                <EventDetails SeasonID={this.state.SelectedSeasonID} RaceNumber={this.state.SelectedRaceNumber} TypeID={this.state.SelectedRaceTypeID} />

                <EventResultsTable SeasonID={this.state.SelectedSeasonID} RaceNumber={this.state.SelectedRaceNumber} TypeID={this.state.SelectedRaceTypeID} Gender={this.state.SelectedGender} />

            </div>
        }

        return (
            content
        );
    }

    // don't update the selected states until all of the drodowns have been updated
    async getAllSeasons() {
        const seasonResponse = await fetch('/Stats/GetAllSeasons');
        const seasons = await seasonResponse.json();

        this.setState({ Seasons: seasons });

        if (this.state.SelectedSeasonID !== null) {
            this.getAllRacesForSeason(this.state.SelectedSeasonID)
        }
        else {
            this.getAllRacesForSeason(seasons[0].id)
        }
    }
    
    async getAllRacesForSeason(seasonID) {
        const raceNumbersResponse = await fetch(`/Stats/GetAllRacesForSeason?aSeasonID=${seasonID}`);
        const raceNumbers = await raceNumbersResponse.json();

        this.setState({ RaceNumbers: raceNumbers });
        this.getAllTypesForRace(seasonID,
            this.state.SelectedRaceNumber == null ? seasonID === this.state.Seasons[0].id ? raceNumbers[raceNumbers.length - 1] : raceNumbers[0] :
                this.state.SelectedRaceNumber
                )
    }

    async getAllTypesForRace(seasonID, raceNumber) {
        const typesResponse = await fetch(`/Stats/GetAllTypesForRace?aSeasonID=${seasonID}&aRaceID=${raceNumber}`);
        const types = await typesResponse.json();
    
        let selectedTypeID = this.state.SelectedRaceTypeID;
        
        if (types.filter(function (e) { return e.id === parseInt(selectedTypeID); }).length > 0) {
            this.setState({ SelectedSeasonID: seasonID, SelectedRaceNumber: raceNumber, RaceTypes: types, Loading: false }, this.updateHistory);
        }
        else {
            this.setState({ SelectedSeasonID: seasonID, SelectedRaceNumber: raceNumber, RaceTypes: types, SelectedRaceTypeID: types[0].id, Loading: false }, this.updateHistory);
        }
    }
}