import React, { Component } from 'react';

export class AthleteResultsSummaryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Results: props.Results,
        }
    }

    // Update when the the props passed to the component are changed
    componentDidUpdate(prevProps) {
        if (prevProps.Results !== this.props.Results) {
            this.setState({
                Results: this.props.Results,
            });
        }
    }

    render() {
        let lRows = [];

        if (this.state.Results !== null) {

            let filtertdResults = this.state.Results.filter(obj => obj.isDuathlon !== true && obj.rawTimeString !== "");
            filtertdResults = GroupBy(filtertdResults, "raceType");

            for (const property in filtertdResults) {
                let worstIndex = 0;
                let bestIndex = 0;

                let totalTimeMinutes = 0;

                for (let i = 0; i < filtertdResults[property].length; i++) {
                    if (filtertdResults[property][i].rawTime.value.totalMinutes > filtertdResults[property][worstIndex].rawTime.value.totalMinutes) {
                        worstIndex = i;
                    }
                    else if (filtertdResults[property][i].rawTime.value.totalMinutes < filtertdResults[property][bestIndex].rawTime.value.totalMinutes) {
                        bestIndex = i;
                    }

                    totalTimeMinutes += filtertdResults[property][i].rawTime.value.totalMinutes;
                }

                let avgTimeMinutes = totalTimeMinutes / filtertdResults[property].length;

                lRows.push( 
                    <tr key={property}>
                        <td>{property}</td>
                        <td>{filtertdResults[property][bestIndex].rawTimeString}</td>
                        <td>{ConvertMinsToHrsMinsSecs(avgTimeMinutes)}</td>
                        <td>{filtertdResults[property][worstIndex].rawTimeString}</td>
                    </tr>)
            } 
        }
        return (
            <div className="row">
                <div className="col-12">
                    <table className="resultsTable" style={{textAlign:"center"}}>
                        <thead>
                            <tr style={{color:"white"}}>
                                <th>Event Type</th>
                                <th>Best</th>
                                <th>Average</th>
                                <th>Worst</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function GroupBy (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

function ConvertMinsToHrsMinsSecs(mins) {
    let h = Math.floor(mins / 60);
    let m = Math.floor(((mins / 60) % 1 * 60));
    let s = (mins / 60 % 1 * 60 % 1);
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s.toFixed(2).split(".")[1];
    return `${h}:${m}:${s}`;
}