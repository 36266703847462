import React, { Component } from 'react';
// Displays an error message rather than an empty screen if an error occurs while rendering
export class ErrorBoundary extends Component {
    static displayName = ErrorBoundary.name;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="container" style={{ padding: "20px", minHeight: "300px" }}>
                    <div className="row">
                        <div className="col-12">
                            <h1>Error</h1>
                            <p>A problem has occurred.</p>
                            <p><a href="http://www.bendigotriathlon.com/">return to homepage</a></p>
                        </div>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}
