import React, { Component } from 'react';

export class EventDetails extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
            Loading: true,
            SelectedSeasonID: props.SeasonID,
            SelectedRaceNumber: props.RaceNumber,
            SelectedRaceTypeID: props.TypeID,
       
            RaceCategory: null,
            SeasonStart: null,
            SeasonEnd: null,
            RaceDate: null,
            RaceLocation: null,
            IsDuathlon: null,
            LegOneDistance: null,
            LegTwoDistance: null,
            LegThreeDistance: null
        }
    }

     // Called once on initial load
    componentDidMount() {
        this.getEventDetails();
    } 

    // Update when the the props passed to the component are changed
    componentDidUpdate(prevProps) {
        if (prevProps.SeasonID !== this.props.SeasonID || prevProps.RaceNumber !== this.props.RaceNumber || prevProps.TypeID !== this.props.TypeID) {
            this.setState({
                SelectedSeasonID: this.props.SeasonID,
                SelectedRaceNumber: this.props.RaceNumber,
                SelectedRaceTypeID: this.props.TypeID,
            }, this.getEventDetails);
        }
    }

    render() {
        let season = "";

        if (this.state.SeasonStart && this.state.SeasonEnd) {
            let start = this.state.SeasonStart.toString().substring(0, 2)
            let end = this.state.SeasonEnd.toString().substring(0, 2)

            if (start === end) {
                season = this.state.SeasonStart + "-" + this.state.SeasonEnd.toString().substring(2, 4);
            }
            else {
                season = this.state.SeasonStart + "-" + this.state.SeasonEnd;
            }
        }

        return (
            <div className="row">
                <div className="col-12" style={{marginTop: "20px", marginBottom: "20px"}}>
                    <h1>{`Villawood Summer Series ${season}`}</h1>
                    <h3>{`Results by Category: ${this.state.RaceCategory}`}</h3>
                    <h3>{`Date: ${this.state.RaceDate}`}</h3>
                </div>
                <div className="col-12 col-md-3" style={{marginBottom: "20px"}}>
                    <h4>Location: {this.state.RaceLocation}</h4>
                </div>
                <div className="col-12 col-md-3" style={{ marginBottom: "20px" }}>
                    <h4>{this.state.IsDuathlon === true ? "Run Distance: " + this.state.LegOneDistance + "km" : "Swim Distance: " + this.state.LegOneDistance + "m"}</h4>
                </div>
                <div className="col-12 col-md-3" style={{ marginBottom: "20px" }}>
                    <h4>Bike Distance: {this.state.LegTwoDistance}km</h4>
                </div>
                <div className="col-12 col-md-3" style={{ marginBottom: "20px" }}>
                    <h4>Run Distance: {this.state.LegThreeDistance}km</h4>
                </div>
            </div>
        );
    }

    async getEventDetails() {
        const eventResponse = await fetch(`/Stats/GetRaceDetails?aSeasonID=${this.state.SelectedSeasonID}&aRaceNumber=${this.state.SelectedRaceNumber}&aTypeID=${this.state.SelectedRaceTypeID}`);
        const event = await eventResponse.json();

        this.setState({
            Loading: false,
            RaceNumber: event.raceNumber,
            RaceCategory: event.raceCategory,
            SeasonStart: event.raceSeasonStart,
            SeasonEnd: event.raceSeasonEnd,
            RaceDate: event.displayDate,
            RaceLocation: event.location,
            IsDuathlon: event.isDuathlon,
            LegOneDistance: event.legOneDistance,
            LegTwoDistance: event.legTwoDistance,
            LegThreeDistance: event.legThreeDistance
        });
    }
}