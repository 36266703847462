import React, { Component } from 'react';
import Logo from '../Images/cropped-tri-club-logo.png';
import { ErrorBoundary } from './ErrorBoundary';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <div className="header" style={{ marginBottom: "30px", minHeight: "128px" }}>
                    <a href="http://www.bendigotriathlon.com/"><img src={Logo} alt="Bendigo Triathlon CLub Logo" style={{ width: "100%", maxWidth: "327px" }} /></a>
                </div>

                <ErrorBoundary>
                     {this.props.children}
                </ErrorBoundary>

                <div style={{ backgroundColor: "#f8f8f8", padding: "20px", marginTop: "30px", textAlign: "center", color: "#666666" }}>
                    <div> &copy; {(new Date().getFullYear())} Bendigo Triathlon Club</div>
                </div>
            </div>
        );
    }
}
